import { Component, inject, ViewChild } from '@angular/core';
import { ActivatedRoute, RouterOutlet, Data, Router } from '@angular/router';
import { HeaderComponent } from './@shared/header/header.component';
import { ContactFormComponent } from './@shared/contact-form/contact-form.component';
import { FooterComponent } from './@shared/footer/footer.component';

@Component({
  selector: 'boost-frontend-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, ContactFormComponent, FooterComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  private readonly router = inject(Router);

  hideForm = false;

  onActivate(event: any) {
    // we need to check if the activated route has data to hide the form 
    // (see public.routes.ts -> terms)
    const activatedRoute = this.router.routerState.root.firstChild;
    const routerData: Data = activatedRoute ? activatedRoute.snapshot.data : {};
    this.hideForm = routerData['hideForm'] || false;
  }
}
