<section>
  <div class="container">
    <h1 class="text-primary text-center">Partner</h1>
  </div>
  <div class="w-full bg-primary pb-5 md:pb-0">
    <div class="container text-white text-center">
      <h1>with BOOST</h1>

      <div class="flex justify-center text-left">
        <div class="w-full md:w-2/3 flex gap-5 flex-col md:flex-row">
          <div class="w-full md:w-1/2 py-8 flex flex-col gap-4">
            <p>
              Becoming a BOOST Partner is an opportunity to improve people's
              lives. We're here to support you with every step of the way.
            </p>
            <p>
              If you have specific needs or goals that aren't directly covered
              in the information on our site, please reach out to us. We're
              happy to discuss how a partnership with BOOST can be tailored to
              meet your unique objectives.
            </p>
            <p>
              Let's talk about how we can work together to create real impact.
            </p>
            <h5>Let's drive<br/>social<br/>impact<br/>together</h5>
          </div>
          <div class="w-full md:w-1/2 md:py-8">
            <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
              <div class="mb-2">
                <label class="block test-sm font-bold mb-1" for="name">
                  Name
                </label>
                <input
                  class="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 text-sm leading-tight text-gray-700 shadow focus:outline-none"
                  type="text"
                  formControlName="name"
                  placeholder="Name *" />
                @if (
                  formGroup.get("name")?.touched &&
                  formGroup.get("name")?.hasError("required")
                ) {
                  <div class="block text-yellow-400 text-sm">
                    Name is required
                  </div>
                }
              </div>
              <div class="mb-2">
                <label class="block test-sm font-bold mb-1" for="email">
                  Email
                </label>
                <input
                  class="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 text-sm leading-tight text-gray-700 shadow focus:outline-none"
                  type="email"
                  formControlName="email"
                  placeholder="Email *" />
                @if (
                  formGroup.get("email")?.touched &&
                  formGroup.get("email")?.hasError("pattern")
                ) {
                  <div class="block text-yellow-400 text-sm">
                    Email is invalid
                  </div>
                } @else if (
                  formGroup.get("email")?.touched &&
                  formGroup.get("email")?.hasError("required")
                ) {
                  <div class="block text-yellow-400 text-sm">
                    Email is required
                  </div>
                }
              </div>
              <div class="mb-2">
                <label class="block test-sm font-bold mb-1" for="phone">
                  Phone
                </label>
                <input
                  class="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 text-sm leading-tight text-gray-700 shadow focus:outline-none"
                  type="text"
                  formControlName="phone"
                  placeholder="Phone number" />
              </div>
              <div class="mb-2">
                <label class="block test-sm font-bold mb-1" for="company">
                  Company
                </label>
                <input
                  class="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 text-sm leading-tight text-gray-700 shadow focus:outline-none"
                  type="text"
                  formControlName="company"
                  placeholder="Company *" />
                @if (
                  formGroup.get("company")?.touched &&
                  formGroup.get("company")?.hasError("required")
                ) {
                  <div class="block text-yellow-400 text-sm">
                    Company is required
                  </div>
                }
              </div>
              <div class="mb-6">
                <label class="block test-sm font-bold mb-1" for="message">
                  Message
                </label>
                <textarea
                  class="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 text-sm leading-tight text-gray-700 shadow focus:outline-none"
                  formControlName="message"
                  placeholder="Leave a message"
                  [rows]="5"></textarea>
              </div>
              <div class="form__actions">
                <button
                  class="over-dark"
                  type="submit"
                  [class.busy]="isSent$ | async"
                  [disabled]="!formGroup.valid">
                  Send
                </button>
              </div>
              @if (errorMessage) {
                <div class="partner-container__full__right__error">
                  <p>{{ errorMessage }}</p>
                </div>
              }
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<dialog #confirmDialog class="relative z-10">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
  <div class="fixed inset-0 z-10 w-screen h-screen flex justify-center items-center">
    <div class="bg-white p-5 rounded-lg flex flex-col gap-3 w-[90%] md:w-1/3 items-center">
      <p>{{ dialogMessage }}</p>
      <div class="button" (click)="closeDialog()" >OK</div>
    </div>
  </div>
</dialog>
