export const environment = {
  firebase: {
    apiKey: "AIzaSyAXFatKjgMuE867JVarD5Nwm6QjptaXcYk",
    authDomain: "boost-develop.firebaseapp.com",
    projectId: "boost-develop",
    storageBucket: "boost-develop.appspot.com",
    messagingSenderId: "123353480465",
    appId: "1:123353480465:web:81f622403dd9405ff960b0",
    measurementId: "G-3DW9DCV2R1"
  },
  firebaseEmulatorURL: undefined,
};
