<header class="header-background fixed z-header w-full">
  <div class="container pb-[1px] mx-[-40px] w-[calc(100%+80px)] md:mx-auto md:w-full">
    <div class="bg-white flex flex-col md:flex-row gap-10">
      <div class="relative w-full md:w-1/4">
        <div class="absolute left-0 top-0 flex h-full min-h-16 md:min-h-24 w-full justify-center bg-primary px-5">
          <a routerLink="/" >
            <img src="images/boost-logo-white.svg" alt="boost logo" class="w-full h-full max-w-[150px]" />
          </a>
        </div>
      </div>
      <div class="mt-6 flex w-full flex-col items-center justify-center md:mt-0 md:w-2/4">
        <div class="flex flex-row items-stretch min-h-12 text-xs md:text-base">
          <a routerLink="/creators" routerLinkActive="bg-gray-200" class="flex items-center px-2 md:px-5 font-bold uppercase transition-colors hover:text-primary">Creators</a>
          <a routerLink="/contributors" routerLinkActive="bg-gray-200" class="flex items-center px-2 md:px-5 font-bold uppercase transition-colors hover:text-primary">Contributors</a>
          <a routerLink="/partners" routerLinkActive="bg-gray-200" class="flex items-center px-2 md:px-5 font-bold uppercase transition-colors hover:text-primary">Industry partners</a>
        </div>
      </div>
    </div>
  </div>
</header>
