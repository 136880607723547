import { Routes } from '@angular/router';


export const PUBLIC_ROUTES: Routes = [
  {
    path: '',
    loadComponent: () => import('./home/home.component').then(m => m.HomeComponent)
  },
  {
    path: 'creators',
    loadComponent: () => import('./creators/creators.component').then(m => m.CreatorsComponent)
  },
  {
    path: 'contributors',
    loadComponent: () => import('./contributors/contributors.component').then(m => m.ContributorsComponent)
  },
  {
    path: 'partners',
    loadComponent: () => import('./partners/partners.component').then(m => m.PartnersComponent)
  },
  {
    path: 'terms',
    loadComponent: () => import('./terms/terms.component').then(m => m.TermsComponent),
    data: {
      hideForm: true
    }
  },
  {
    path: 'privacy',
    loadComponent: () => import('./privacy/privacy.component').then(m => m.PrivacyComponent),
    data: {
      hideForm: true
    }
  },
  {
    path: 'diversity',
    loadComponent: () => import('./diversity/diversity.component').then(m => m.DiversityComponent),
    data: {
      hideForm: true
    }
  }
];
