<footer class="footer-background mt-10">
  <div class="md:container bg-primary flex items-center md:items-end flex-col md:flex-row">
    <div class="flex min-h-full w-full md:w-3/4 flex-row md:flex-col md:pr-10 py-10">
      <div class="flex flex-col md:flex-col gap-5 w-full md:min-h-full">
        <div class="flex flex-col md:flex-row gap-5 md:gap-16 items-center md:min-h-full">
          <a routerLink="/" >
            <img src="images/boost-logo-complete-white.svg" alt="boost logo" class="-mt-5 w-full h-full max-w-[150px] cursor-pointer" />
          </a>
          <a [routerLink]="'/creators'" class="cursor-pointer font-bold uppercase text-white transition-colors hover:text-primary-200">Creators</a>
          <a [routerLink]="'/contributors'" class="cursor-pointer font-bold uppercase text-white transition-colors hover:text-primary-200">Contributors</a>
          <a [routerLink]="'/partners'" class="cursor-pointer font-bold uppercase text-white transition-colors hover:text-primary-200">Industry partners</a>
        </div>
        <div class="flex flex-col md:flex-row items-center md:items-end text-white w-full gap-4">
          <div class="text-center">Copyright © {{ today | date:'yyyy'}} BOOST.<br class="block md:hidden" />All rights reserved.</div>
          <a class="md:ml-auto text-xs" [routerLink]="'/terms'">Terms & Conditions</a>
          <a class="text-xs" [routerLink]="'/diversity'">Diversity & Equality</a>
          <a class="text-xs" [routerLink]="'/privacy'">Privacy & Accesibility</a>
        </div>
      </div>
    </div>
    <div class="bg-black w-full md:w-1/4 py-10 min-h-full text-white flex flex-col items-center md:items-end">
      <div class="text-xs">Contact us</div>
      <a class="font-medium" href="mailto:info@metacampus.ai">info&#64;metacampus.ai</a>
      <div class="flex gap-4 mt-3 mb-10">
        <a href="https://x.com/metacampusai" target="_blank" rel="noopener noreferrer">
          <img src="images/x-icon.svg" alt="X" class="w-6 h-6" />
        </a>
        <a href="https://www.linkedin.com/company/metacampus-ai" target="_blank" rel="noopener noreferrer">
          <img src="images/linkedin-icon.svg" alt="Linkedin" class="w-6 h-6" />
        </a>
        <a href="https://www.youtube.com/@metacampusAI" target="_blank" rel="noopener noreferrer">
          <img src="images/youtube-icon.svg" alt="Youtube" class="w-6 h-6" />
        </a>
        <!-- <a href="#" target="_blank" rel="noopener noreferrer">
          <img src="images/instagram-icon.svg" alt="Instagram" class="w-6 h-6" />
        </a> -->
      </div>
      <div class="text-xs">Powered by</div>
      <a href="https://www.metacampus.ai" target="_blank"><img src="images/metacampus-logo.svg" alt="Metacampus" class="w-36 h-auto" /></a>
    </div>
  </div>
</footer>
