import { Injectable, inject } from "@angular/core";
import { Observable, of } from "rxjs";

export interface IForm {
  name: string;
  email: string;
  phone?: string;
  company?: string;
  message?: string;
}

@Injectable()
export class ContactFormService {

  sendForm(formData: IForm): Observable<boolean> {
    console.log('Sending form data:', formData);

    return of(true);
  }
}
