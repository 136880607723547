import {
  Component,
  DestroyRef,
  ElementRef,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ContactFormService, IForm } from './contact-form.service';
import { BehaviorSubject } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AsyncPipe } from '@angular/common';
import { Functions, httpsCallable } from '@angular/fire/functions';

const EMAIL_PATTERN = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$';

@Component({
  selector: 'boost-frontend-contact-form',
  standalone: true,
  imports: [ReactiveFormsModule, AsyncPipe],
  providers: [ContactFormService],
  templateUrl: './contact-form.component.html',
  styleUrl: './contact-form.component.scss',
})
export class ContactFormComponent implements OnInit {
  @ViewChild('confirmDialog') confirmDialog!: ElementRef<HTMLDialogElement>;

  private readonly formBuilder = inject(FormBuilder);
  private readonly contactFormService = inject(ContactFormService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly functions: Functions = inject(Functions);

  isSent$ = new BehaviorSubject(false);
  dialogMessage = '';

  errorMessage: string | null = null;
  formGroup!: FormGroup;

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      name: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
      phone: [null],
      company: [null, [Validators.required]],
      message: [null],
    });
  }

  onSubmit(): void {
    this.isSent$.next(true);
    const {
      value: { name, email, phone, company, message },
    } = this.formGroup;
    httpsCallable(
      this.functions,
      'http-forms-contactForm',
    )({
      name,
      email,
      phone,
      company,
      message,
    }).then(
      (response) => {
        this.dialogMessage =
          "Thank you for submitting your details. We've received them, and you'll receive an email from us shortly with more information. Stay tuned!";
        this.confirmDialog.nativeElement.showModal();
        this.isSent$.next(false);
        this.formGroup.reset();
      },
      (error) => {
        this.dialogMessage =
          'Something went wrong while submitting your details. Please try again, and we look forward to connecting with you soon. Thank you for your patience!';
        this.confirmDialog.nativeElement.showModal();
        this.isSent$.next(false);
      },
    );
  }

  closeDialog() {
    this.confirmDialog.nativeElement.close();
  }
}
